exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact/confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-11-nuc-power-consumption-index-mdx": () => import("./../../../src/templates/mdx.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/posts/2022/11/nuc-power-consumption/index.mdx" /* webpackChunkName: "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-11-nuc-power-consumption-index-mdx" */),
  "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-11-vortex-core-index-mdx": () => import("./../../../src/templates/mdx.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/posts/2022/11/vortex-core/index.mdx" /* webpackChunkName: "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-11-vortex-core-index-mdx" */),
  "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-12-varmilo-support-index-mdx": () => import("./../../../src/templates/mdx.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/posts/2022/12/varmilo-support/index.mdx" /* webpackChunkName: "component---src-templates-mdx-js-content-file-path-gatsby-www-kumashun-com-src-mdx-posts-2022-12-varmilo-support-index-mdx" */),
  "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-about-mdx": () => import("./../../../src/templates/mdx_simple.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/about.mdx" /* webpackChunkName: "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-about-mdx" */),
  "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-contact-mailaddress-mdx": () => import("./../../../src/templates/mdx_simple.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/contact/mailaddress.mdx" /* webpackChunkName: "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-contact-mailaddress-mdx" */),
  "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-terms-mdx": () => import("./../../../src/templates/mdx_simple.js?__contentFilePath=/gatsby/www.kumashun.com/src/mdx/terms.mdx" /* webpackChunkName: "component---src-templates-mdx-simple-js-content-file-path-gatsby-www-kumashun-com-src-mdx-terms-mdx" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

